import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31bf5a92 = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _3bbfbe9e = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _950497ca = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _3fe8ae70 = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _71e2ec2e = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _f9bbfafa = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _719050a6 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _0d0ba08a = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _fcd05d44 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _5a4f670a = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _23d1c6fe = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _740cfd06 = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _27ffaf3a = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _34c7ba16 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _14ac7eb6 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _5736529b = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _8d0b4128 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _31bf5a92,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _3bbfbe9e,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _950497ca,
    name: "callback"
  }, {
    path: "/downloads",
    component: _3fe8ae70,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _71e2ec2e,
    name: "guests-details"
  }, {
    path: "/login",
    component: _f9bbfafa,
    name: "login"
  }, {
    path: "/maintenance",
    component: _719050a6,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _0d0ba08a,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _fcd05d44,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _5a4f670a,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _23d1c6fe,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _740cfd06,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _27ffaf3a,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _34c7ba16,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _14ac7eb6,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _5736529b,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _8d0b4128,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
